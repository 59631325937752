import React, { useState } from 'react'
import Layout from '../components/layout'
import { Container, Row, Col, Carousel, CarouselItem, CarouselIndicators } from 'reactstrap'
import banner from '../images/kapal.jpg'
import umah from '../images/umah.jpg'
import meliumah from '../images/meli-umah.jpg'

import aha from '../images/imagesforpages4.png'
import { seoData } from '../data/seo-data'
import logo from '../images/logo-black.png'
import { BookAFreeConsultations } from '../components/book-a-free-consultations'
import help from "../images/consultant.png"
import ijanpis from '../images/ujan-pis.png'
import build from '../images/build-smsf.png'
const PersonalSolutions = () => {

    const [uid, setUid] = useState("")

    return (
        <Layout
            seo={{
                title: seoData.personalSolutions.title, description: seoData.personalSolutions.description, keyword: ''
            }}
            banner={<FreeConsulTationBanner />}
            navStayle="SIMPLE"
            setUid={(uid: string) => setUid(uid)}
        >
            <section style={{ marginBottom: 50, marginTop: 50 }}>
                <Container style={{ textAlign: "center" }}>
                    <h3 className="header-font" >Self-Managed Super Funds</h3>
                    <h5 style={{ fontSize: 16 }}>With the current economic climate more people are choosing to take control of their superannuation. <br /> One of the considerations is a Self Managed Super Fund - SMSF.</h5>
                </Container>
            </section>

            <section  >
                <Container>
                    <h3 style={{ color: 'rgb(1, 107, 146)' }}>What is an SMSF?</h3>
                    <p style={{ fontSize: 16 }}>A self managed super fund is a private super fund managed by a trustee/s.  It is effectively controlled by you and or up to 3 other trustees who generally will also be members of the SMSF, giving you more control over how your retirement savings are invested.</p>
                    <p>An SMSF can have up to 4 members pooling the funds together and investing in accordance to the rules set by the trustee/s.</p>
                    <h4 style={{ color: 'rgb(1, 107, 146)' }}>How does an SMSF work?</h4>
                    <p style={{ fontSize: 16 }}>Members are often closely tied, such as married couples and family members. The members work together to invest and grow their super just like a regular superfund however with additional benefits such as investing in direct property, art, collectibles, etc.  Basically items a regular superfund would not be inclined to invest in given that it would be extremely difficult to sell a part of the asset if a member chose to liquidate their portion or leave the fund.</p>
                    <p>However, every coin has two sides. On one hand you have more control over your funds, but the flip side is that you as the trustee have more responsibility, so it is worth considering the pros and cons.</p>
                    <a href="#contact" className="btn" style={{ backgroundColor: '#ffb236' }}><span style={{ fontSize: 14, fontWeight: 800 }}> Let’s have a chat about how we can help you</span></a>
                    <Row style={{ marginTop: 50 }}>
                        <Col md={7}>
                            <img src={aha} alt="" />
                        </Col>
                        <Col md={5}  >
                            <h3 style={{ color: 'rgb(1, 107, 146)' }}>Do you think an SMSF is an option for you?</h3>
                            <p style={{ fontSize: 16 }}>It could be if….</p>
                            <ul>
                                <li style={{ fontSize: 16 }}>You have the funds to invest (required combined $200,000 or more)</li>
                                <li style={{ fontSize: 16 }}>You want more control of your super, and you are willing to take more responsibility to achieve your goals </li>
                                <li style={{ fontSize: 16 }}>You want a wider choice of investment flexibility</li>
                                <li style={{ fontSize: 16 }}>You want better visibility of how your investments are performing over time</li>
                            </ul>
                            <p>If the answer is yes to the questions above call us and we will guide through a tailored plan.</p>
                        </Col>
                    </Row>
                </Container>
            </section >
            <section style={{ padding: "100px 75px", backgroundColor: "#016b92cf", marginTop: 50 }}>
                <Container>
                    <Row>
                        <Col md={6} style={{ marginTop: '5%' }}>
                            <h3 style={{ color: '#fff' }}>How can Solutions help?</h3>
                            <ul>
                                <li style={{ fontSize: 16, color: '#fff' }}>Determine your eligibility and review all preliminary requirements</li>
                                <li style={{ fontSize: 16, color: '#fff' }}>Establish your SMSF</li>
                                <li style={{ fontSize: 16, color: '#fff' }}>Create a Trust Deed and Set up TFN, ABN and bank accounts.</li>
                                <li style={{ fontSize: 16, color: '#fff' }}>Complete your compliance requirements and keep you up to date</li>
                                <li style={{ fontSize: 16, color: '#fff' }}>Provide investment recommendations</li>
                            </ul>
                            <p style={{ color: '#fff' }}>Book a FREE consultation to discuss your eligibility for an SMSF, to see whether it’s right for you.</p>

                        </Col>
                        <Col md={6}>
                            <img src={help} alt="" />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id="contact" style={{ padding: "100px 0 50px", backgroundColor: "#fff" }}>
                <BookAFreeConsultations uid={uid} />
            </section>

        </Layout>
    )
}

const FreeConsulTationBanner = () => {

    const items = [
       
        { 
            src: 1,
            isi: <div style={{ backgroundImage : `url(${banner})`, backgroundPosition: "center", backgroundSize: 'cover', minHeight: 635}}  >
            <Container>
                <Row>
                    <Col md={5}>
                        <div style={{ marginTop: '30%', padding: '10px 0' }}>
                            <img style={{ marginTop: 0 }} src={logo} alt="" />
                            <h3 className="header-font" style={{ color: '#fff', fontSize: '7.5em', marginBottom: 0, width: 'auto' }}>SMSF </h3>
                            <img src={build} alt="" style={{ marginBottom: 50}} />
                        </div>
                    </Col>
                    <Col md={7}> 
                        
                    </Col>
                </Row>
            </Container>

        </div>
        }, { 
            src: 2,
            isi: <div style={{ backgroundImage : `url(${umah})`, backgroundPosition: "center", backgroundSize: 'cover', minHeight: 635}}  >
            <Container>
                <Row>
                    <Col md={5}>
                        <div style={{ marginTop: '30%', padding: '10px 0' }}>
                            <img style={{ marginTop: 0 }} src={logo} alt="" />
                            <h3 className="header-font" style={{ color: '#fff', fontSize: '7.5em', marginBottom: 0, width: 'auto' }}>SMSF </h3>
                            
                        </div>
                    </Col>
                    <Col md={7}> 
                    <img src={meliumah} alt="" style={{ marginBottom: 50, marginTop: '70%'}} />
                    </Col>
                </Row>
            </Container>

        </div>
        },  { 
            src: 3,
            isi: <div style={{ background: "#006a91", minHeight: 635, }}  >
            <Container>
                <Row>
                    <Col md={5}>
                        <div style={{ marginTop: '30%', padding: '10px 0' }}>
                            <img style={{ marginTop: 0 }} src={logo} alt="" />
                            <h3 className="header-font" style={{ color: '#fff', fontSize: '7.5em', marginBottom: 0, width: 'auto' }}>SMSF </h3>
                            <h3 style={{fontWeight: 800, color: "rgb(1 106 145)", background: "#ffd402", textTransform: 'uppercase', padding: '10px 30px'}}>You Choose The Investment Options</h3>
                        </div>
                    </Col>
                    <Col md={7}>
                        <img src={ijanpis} alt=""  style={{marginTop: 110}}/>
                    </Col>
                </Row>
            </Container>

        </div>
        }, 
    ];
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    const goToIndex = (newIndex: any) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };
    return (
        <>
            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                <CarouselIndicators
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                />
                {items.map(item => {
                    return (
                        <CarouselItem
                            onExiting={onExiting}
                            onExited={onExited}
                            key={item.src}
                        >

                             {item.isi}
                        </CarouselItem>
                    );
                })}
                <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                        e.preventDefault();
                        previous();
                    }}
                    role="button"
                >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                </a>
                <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                        e.preventDefault();
                        next();
                    }}
                    role="button"
                >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                </a>
            </Carousel>

        </>
    )
}

export default PersonalSolutions

/*

<div style={{ backgroundImage: `url(${banner})`, backgroundSize: 'cover', backgroundPosition: 'center', minHeight: 600 }}>
                <Container>
                    <Row style={{ paddingBottom: 50, textAlign: "center" }}>
                        <Col md={6} >
                            <div style={{ marginTop: '50%', padding: '10px 0' }}>
                                <img style={{ marginTop: 0 }} src={logo} alt="" />
                                <h3 className="header-font" style={{ color: '#fff', fontSize: '7.5em', marginBottom: 0, width: 'auto' }}>SMSF </h3>
                            </div>
                        </Col>
                        <Col md={6} >

                        </Col>
                    </Row>
                </Container>
            </div>

*/